import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private darkMode: BehaviorSubject<boolean>;
  darkMode$: Observable<boolean>;
  private isMobileDevice: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.darkMode = new BehaviorSubject<boolean>(this.getInitialDarkMode());
    this.darkMode$ = this.darkMode.asObservable();

    if (isPlatformBrowser(this.platformId)) {
      this.isMobileDevice = this.checkIfMobileDevice();
      if (this.isMobileDevice)
        this.detectSystemTheme();
      else {
        const savedDarkMode = localStorage.getItem('DarkMode');
        const isDarkMode = savedDarkMode === 'true';
        this.darkMode = new BehaviorSubject<boolean>(isDarkMode);
        this.darkMode$ = this.darkMode.asObservable();
      }
    }
  }

  private getInitialDarkMode(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const savedDarkMode = localStorage.getItem('DarkMode');
      return savedDarkMode === 'true' || savedDarkMode === null && this.isSystemDarkMode();
    }
    return false; // Default to light mode if not in a browser
  }

  private isSystemDarkMode(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  private detectSystemTheme(): void {
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    // Update dark mode based on the initial system theme
    this.setDarkMode(darkModeMediaQuery.matches);

    // Listen for changes in the system theme
    darkModeMediaQuery.addEventListener('change', (event) => {
      this.setDarkMode(event.matches);
    });
  }

  private checkIfMobileDevice(): boolean {
    return window.matchMedia('(max-width: 1023px)').matches;
  }

  isMobile(): boolean {
    return this.isMobileDevice;
  }

  toggleDarkMode(): void {
    const newDarkModeValue = !this.darkMode.value;
    this.darkMode.next(newDarkModeValue);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('DarkMode', newDarkModeValue.toString());
    }
  }

  setDarkMode(isDarkMode: boolean): void {
    this.darkMode.next(isDarkMode);
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('DarkMode', isDarkMode.toString());
    }
  }
}
